<template>
  <div class="content">
    <sub-title>스포츠 베팅내역</sub-title>
    <div class="sports_bet_list">
      <bet-history-links></bet-history-links>

      <!--배팅내역 리스트-->
      <sports-bet-history-details
          :bet-list="betList"
          @cancelOrDelBet="cancelOrDelBet"
          @betInfoChecked="betInfoChecked"
          :position="1"></sports-bet-history-details>

    </div>

    <pagination :page-index="pageNum"
                :total="total"
                :page-size="pageSize"
                @change="pageChange"></pagination>

    </div>

</template>


<script>
    import Pagination from "@/components/pagenation/Pagination";
    import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "@/store/mutation-types";
    import {postionMixin} from "@/common/mixin";
    import sportsConst from "@/common/sportsConst";
    import SubTitle from "@/views/afront/zero/SubTitle.vue";
    import {getBetList} from "@/network/sportsBetRequest";
    import {fix2, kindText} from "@/common/SportsUtils";
    import SportsBetHistoryDetails from "@/views/afront/sports/match/SportsBetHistoryDetails.vue";
    import BetHistoryLinks from "@/views/afront/sports/match/BetHistoryLinks.vue";

    export default {
      name: "SportsBetHistory",
      mixins: [postionMixin],
      components: {
        BetHistoryLinks,
        SportsBetHistoryDetails,
        Pagination, SubTitle,

      },
      data() {
        return {
          sportsConst,
          pageNum: 1,
          pageSize: 5,
          total: 1,
          orderBy: 'b.id DESC',
          search: {
            betResult: null
          },
          selectedBetIds: [],
          betList: [],
          position:"S베팅내역",
        }
      },
      methods: {
        /*전체,당첨,낙첨,..버튼 클릭시*/
        changeBetResult(betResult) {
          this.pageNum = 1
          this.search.betResult = betResult
          this.initBetList()
        },
        /*SportsBetListComp.vue에서 취소 또는 삭제시 베팅내역 새로고침*/
        cancelOrDelBet() {
          this.initBetList()
        },
        /*SportsBetListComp.vue에서 체크박스 클릭시*/
        betInfoChecked(betIds) {
          this.selectedBetIds = betIds
        },
        initBetList() {
          this.$store.commit(RECEIVE_SHOW_LOADING)

          getBetList(this.search, this.pageNum, this.pageSize, this.orderBy).then(res => {
            if (res.data.success) {
              this.total = res.data.total
              this.betList = res.data.data;
              this.betList.forEach((betInfo) => {
                betInfo.betInfoGameList.forEach((betInfoGame) => {
                  try {
                    //배당 소수점 두자리 설정 1.8 => 1.80
                    fix2(betInfoGame)
                    //승무패,핸디오언 텍스트 설정
                    kindText(betInfoGame.game)
                  } catch (e) {

                  }
                })
              })
            }
            this.$store.commit(RECEIVE_HIDE_LOADING)
          })
        },
        pageChange(page) {
          this.pageNum = page
          this.initBetList()
        }
      },
      created() {
        this.initBetList()
        this.$store.state.currentBetHistoryIndex = 1
      }
    }
</script>

<style scoped>
    @import url("../../../../assets/css/front/afrontsports.css");
</style>